<template>
  <div class="bg-lovin h-100">
    <div class="container h-100 overflow-auto">
      <h2 class="text-center"><router-link class="app-title" to="/admin">Ozzie Lovin</router-link></h2>
      <div>
        <ul class="list-fields">
          <li class="d-flex justify-content-center">
              <input type="text" placeholder="Song title" class="form-control form-input mt-2 w-75" v-model="edit_fields.song_title"/>
          </li>
          <li class="d-flex">
            <div class="d-flex justify-content-center txt-label">
              <img src="../assets/imgs/spotify.png" style="height: 50px">
            </div>
            <input type="text" placeholder="Spotify Link xxxxxxxx" class="form-control form-input mt-2 mr-5" v-model="edit_fields.spotify_url"/>
          </li>
          <li class="d-flex">
            <div class="d-flex justify-content-center txt-label">
              <img src="../assets/imgs/apple.png" style="height: 50px">
            </div>
            <input type="text" placeholder="Apple Music Link xxxx" class="form-control form-input mt-2 mr-5" v-model="edit_fields.apple_url"/>
          </li>
          <li class="d-flex">
            <div class="d-flex justify-content-center txt-label">
              <img src="../assets/imgs/youtube.png" style="height: 50px" >
            </div>
            <input type="text" placeholder="Youtube Link xxxxxxxx" class="form-control form-input mt-2 mr-5" v-model="edit_fields.youtube_url"/>
          </li>
          <li class="d-flex">
            <div class="d-flex justify-content-center txt-label">
              <img src="../assets/imgs/amazon.png" style="height: 50px">
            </div>
            <input type="text" placeholder="Amazon Music Link" class="form-control form-input mt-2 mr-5" v-model="edit_fields.amazon_url"/>
          </li>
        </ul>
        <div class="d-flex justify-content-center txt-label mb-3" style="margin-top: 4rem">
          <button :disabled="loading" type="button" class="btn btn-secondary btn-style1" @click="handleSave()">{{ loading? "Saving...": "Save" }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OzzieLovin from "@/models/Ozzielovin";
import {mapState} from "vuex";

export default {
  name: "EditLinks",
  data() {
    return {
      edit_fields: new OzzieLovin(),
      loading: false
    }
  },
  watch: {
    fields: {
      handler(val) {
        this.edit_fields = val;
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState([
      'fields'
    ]),
  },
  created() {
    this.$store.dispatch('getDataFields')
  },
  methods: {
    handleSave() {
      let that = this;
      that.loading = true
      this.edit_fields.save().then(() => {
        that.loading = false;
      }).catch((e) => {
        alert("Unable to save data. Please try again");
        console.log(e)
      });
    }
  }
}
</script>

<style scoped lang="scss">
.list-fields {
  list-style: none;
  padding-left: 0;
  margin-top: 40px;
  li {
    margin-bottom: 50px;
  }
  .txt-label {
    width: 130px;
  }
}
</style>
